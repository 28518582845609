/* =============
   Widgets
============= */

.tilebox-one {
  background: url("../images/bg-1.png");
  background-size: cover;

  i {
    background: $primary;
    background: $bg-gradient;
    font-size: 24px;
    height: 50px;
    line-height: 50px;
    width: 50px;
    text-align: center;
    color: $white !important;
    border-radius: 50%;
    box-shadow: $box-shadow-lg;
  }
}


/* Inbox-widget */

.inbox-widget {
  .inbox-item {
    border-bottom: 1px solid lighten($gray-100,4%);
    overflow: hidden;
    padding: 10px 0;
    position: relative;
    .inbox-item-img {
      display: block;
      float: left;
      margin-right: 15px;
      width: 40px;
    }
    img {
      width: 40px;
    }
    .inbox-item-author {
      color: $dark;
      display: block;
      margin: 0;
    }
    .inbox-item-text {
      color: $text-muted;
      display: block;
      font-size: 14px;
      margin: 0;
    }
    .inbox-item-date {
      color: $text-muted;
      font-size: 11px;
      position: absolute;
      right: 7px;
      top: 7px;
    }
  }
}


/* Comment List */
.comment-list {
  .comment-box-item {
    position: relative;

    .commnet-item-date {
      color: $text-muted;
      font-size: 11px;
      position: absolute;
      right: 7px;
      top: 2px;
    }
    .commnet-item-msg {
      color: $dark;
      display: block;
      margin: 10px 0;
      font-weight: normal;
      font-size: 15px;
      line-height: 24px;
    }
    .commnet-item-user {
      color: $text-muted;
      display: block;
      font-size: 14px;
      margin: 0;
    }
  }
  a + a {
    margin-top: 15px;
    display: block;
  }
}


/* Transaction */

.transaction-list {
  li{
    padding: 7px 0;
    border-bottom: 1px solid lighten($gray-100,4%);
    clear: both;
    position: relative;
  }
  i{
    width: 20px;
    position: absolute;
    top: 10px;
    font-size: 12px;
  }
  .tran-text {
    padding-left: 25px;
    white-space: nowrap;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 150px;
  }
  .tran-price {
    margin-left: 30px;
  }
}