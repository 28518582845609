/* =============
   Pricing
============= */


.pricing-column{
  .ribbon-pricing {
    width: 160px;
    margin: -15px auto -10px;
    padding-bottom: 2px;
    line-height: 22px;
    text-align: center;
    z-index: 1;
    position: relative;
  }
  .plan-title {
    font-family: $font-family-secondary;
    letter-spacing: 1px;
  }
  .plan-price {
    font-size: 48px;
    font-family: $font-family-secondary;
  }
  .plan-duration {
    font-size: 15px;
    color: rgba($white,0.7);
  }

  .plan-stats {
    padding: 30px 20px 15px;
    li {
      margin-bottom: 15px;
      line-height: 24px;
    }
  }
}

